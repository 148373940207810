import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import { Ring } from 'react-spinners-css';
import { setMaxListeners } from 'events';

export default function Transaction(props) {

    const {fetchTransaction, 
           transaction, 
           setTotal, 
           setFinalTotal,
           total,
           setPaTax,
           id,
           setEmail, setSite,
           setStudent} = props

    useEffect(() => {
    if (id)
     {

        fetchTransaction(id) }

    }, [id])

    useEffect(() => {
        if (transaction && cartTotal)
         {
            setTotal(cartTotal * 100)
            setSite(transaction.site)
            setEmail(transaction.email)
            if (transaction.paTax && transaction.student) {
                setPaTax(true)
                setStudent(true)
                setFinalTotal((cartTotal*.90*1.06 + 6.95)*100)
            } else if (transaction.paTax && !transaction.student) {
                setPaTax(true)
                setFinalTotal((cartTotal*1.06 + 6.95)*100)
                
            } else if (!transaction.paTax && transaction.student){
                setStudent(true)
                setFinalTotal((cartTotal*.90 + 6.95)*100)
            } else {
                setFinalTotal((cartTotal + 6.95)*100)
            }
        }
           
    
        }, [transaction, cartTotal])
    



    let cartTotal = 0

    function findTotal() {
        if (transaction) {
     
                Object.keys(transaction.products).map((key) => {
                cartTotal = cartTotal + transaction.products[key].qty * (transaction.products[key].itemCost) })
            
                  
        }
    }


 
  
        return (


            <div className="">
                {transaction && findTotal() }
              
                
                {transaction && total &&
               
                <div>Enter your payment details to complete your transaction for the amount of ${transaction.total}</div> 
                }
           
             
                        
           
                
            </div>
        )
    }
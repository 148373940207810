import { combineReducers } from 'redux';
import session from './session_reducer';
import user from './users_reducer';
import errors from './errors_reducer';
import transaction from './transaction_api_reducer'

const RootReducer = combineReducers({
  session,
  user,
  errors,
  transaction
});

export default RootReducer;
import React, { useState, useEffect } from 'react';
import {loadStripe} from "@stripe/stripe-js"
import {Link} from 'react-router-dom'

import CheckoutForm from './checkout_form'
import {Elements} from "@stripe/react-stripe-js"
import Transaction from './transaction_container';


export default function MainPage(props) {

  const [open, setOpen] = useState(null)
  const [site, setSite] = useState(null)
  const [stripePromise, setStripePromise] = useState(null)
  const [clientSecret, setClientSecret] = useState("")
  const [total, setTotal] = useState(null)
  const [email, setEmail] = useState(null)
  const [paTax, setPaTax] = useState(false)
  const [finalTotal, setFinalTotal] = useState(null)
  const [student, setStudent] = useState(false);
  const id = props.match.params.id

  const [code, setCode] = useState("");
  const [valid, setValid] = useState(false)
  const [message, showMessage] = useState(false)
  const [discountedtotal, setDiscountedTotal] = useState(null)
  const [buttonClicked, setButtonClicked] = useState(false)

  function handleChange(e) {
      setCode(e.target.value);
  }

 

  useEffect(() => {
    if (finalTotal) {
    fetch("/create-payment-intent", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
    },
      body: JSON.stringify({
        amount: parseInt(finalTotal.toFixed(2)),
        email: email
      }),
    }).then(async (r) => {
      const {clientSecret} = await r.json();
     
      setClientSecret(clientSecret)
    })}
  }, [finalTotal])

  useEffect(() => {
    if (!stripePromise) {
    setStripePromise(loadStripe("pk_live_51ISnMpHDDZI3OmyX3gZ9bQ6sHY71zSHc673Cs6mFnlwofRiPtUJUDMy0wY2JQVcKAHHvRNQ1SHt1na4ghf3eo06s006COsGSea"))
    }
  }, [stripePromise])

  function setOpenSection(num) {
    if (open === num) {
      setOpen(null)
    } else {
      setOpen(num)
    }
  }
  
  console.log(finalTotal)
    return (
      <div className="a">
        <Transaction setStudent={setStudent} setTotal={setTotal} setFinalTotal={setFinalTotal} setPaTax={setPaTax} id={id} setEmail={setEmail} setSite={setSite}/>
        <br/>
        <div className="">
        {total && finalTotal &&
               
               <div>Enter your payment details to complete your transaction 
                <br/><br/>
                Subtotal: <span className='right'>${(parseInt(total) / 100).toFixed(2)}</span>
                <br/>
                <div className='saving-no'>
                {student? <>- Student Discount <span className='right'>${(total/100*.10).toFixed(2)}</span></>: null}
                </div>
                <br/>
                {paTax? <><>{`PA 6% Sales Tax`}</> <span className="right">${(total /100*.06).toFixed(2)}</span></> : null}
                <br/>
                Shipping Flat Rate - All U.S. States <span className='right'>$6.95</span>
                <br/><br/>
                <div className='total'>
                Total: <span className='right'>
                ${(parseInt(finalTotal) /100).toFixed(2)}
                  </span> 
                  </div>
</div> 

               }
        
        
         
        <div className="">
        

                    {stripePromise && clientSecret &&
                    (<Elements stripe={stripePromise} options={{clientSecret}}>
                    <CheckoutForm site={site} />
                    </Elements>)
                    }
                    
                   
        </div>
        </div></div>
        );
    }
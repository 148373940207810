import * as APIUtil from '../util/transaction_api_util';

export const RECEIVE_TRANSACTION = "RECEIVE_TRANSACTION";


export const receiveTransaction = transaction => ({
    type: RECEIVE_TRANSACTION,
    transaction
})

export const fetchTransaction = (id) => dispatch => {
    APIUtil.fetchTransaction(id).then(transaction => {
        dispatch(receiveTransaction(transaction))
    })
}
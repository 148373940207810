import React, { useEffect, useState } from 'react';
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement} from "@stripe/react-stripe-js";

export default function CheckoutForm(props) {

    const {site} = props

  const stripe = useStripe();
  const elements = useElements();


  const [message, setMessage] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async(e) => {
      e.preventDefault();

      if (!stripe || !elements) {
          return
      }

      setIsProcessing(true)

      const {error} = await stripe.confirmPayment({
          elements,
          confirmParams: {
              return_url: `https://shop.cosmetink.com/${site.name}?success`,

          }
      })

      if (error) {
          setMessage(error.message)
      }

      setIsProcessing(false);
  }



    return (
     <form id="payment-form" onSubmit={handleSubmit}>
         <PaymentElement />
         <button disabled={isProcessing} id="submit">
             <span id="button-text">
                 {isProcessing ? "Processing ... " : "Pay now"}
             </span>
         </button>

         {message && <div id="payment-message">{message}</div>}
     </form>
        );
    }
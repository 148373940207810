import { connect } from 'react-redux';
import { login } from '../../actions/session_actions';
import Transaction from './transaction';
import {fetchTransaction} from '../../actions/transaction_actions'

const mapStateToProps = (state) => {
  return {
    transaction: state.transaction.transaction
  
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransaction: id => dispatch(fetchTransaction(id)),
  
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transaction);
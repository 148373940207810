import React, { useState } from 'react';
import {Link} from 'react-router-dom'


export default function Completion(props) {

  const [open, setOpen] = useState(null)

  function setOpenSection(num) {
    if (open === num) {
      setOpen(null)
    } else {
      setOpen(num)
    }
  }
  

    return (
      <div className="main-page">
<h1 className="header">Thank You! We received your payment!<br/>Please check your email for your receipt.</h1>
      </div>
        );
    }